import * as Types from './types';


const findItem = <T extends { id: number }>(items: T[], id: number): T => {
    for (const item of items) {
        if (item.id === id) {
            return item;
        }
    }

    const message = `Cannot find item with id ${id}`;
    console.error(message, items);
    throw new Error(message);
};

const createMapById = <T extends { id: number }>(items: T[]): Map<number, T> => {
    const result: Map<number, T> = new Map();
    items.forEach((item) => {
        result.set(item.id, item);
    });
    return result;
};

const simpleCopy = <T extends object>(source: T, extend: Partial<T>): T => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const result: T = {};

    Object.getOwnPropertyNames(source).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        result[key] = source[key];
    });

    Object.getOwnPropertyNames(extend).forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        result[key] = extend[key];
    });

    return result;
};

const addSimpleCopy = <T extends object>(list: T[], source: T, extend: Partial<T>) => {
    const copy = simpleCopy(source, extend);
    list.push(copy);
};


const targets: Types.ITarget[] = [
    // 0 - Улучшение состояния местных дорог
    {
        id: 0,
        title: 'Улучшение состояния местных дорог'
    },

    // 1 - Улучшение качества и доступности образования
    {
        id: 1,
        title: 'Улучшение качества и доступности образования'
    }
];
const targetMap: Map<number, Types.ITarget> = createMapById(targets);

const targetIndicatorGroups: Types.ITargetIndicatorGroup[] = [
    // 0 - УЭБП
    {
        id: 0,
        title: 'УЭБП'
    },

    // 1 - АБП
    {
        id: 1,
        title: 'АБП'
    }
];
const targetIndicatorGroupMap: Map<number, Types.ITargetIndicatorGroup> = createMapById(targetIndicatorGroups);

const sgpPrts: Types.ISgpPrt[] = [
    // 0 - Нур. Жол
    {
        id: 0,
        title: 'Нур. Жол'
    },

    // 1 - ПРО Н
    {
        id: 1,
        title: 'ПРО Н'
    },

    // 2 - ПРТ
    {
        id: 2,
        title: 'ПРТ'
    }
];
const sgpPrtMap: Map<number, Types.ISgpPrt> = createMapById(sgpPrts);

const targetIndicators: Types.ITargetIndicator[] = [];
// Заполнение targetIndicators
(() => {
    // 0 - Проведение ремонтных работ, км
    targetIndicators.push({
        group: findItem(targetIndicatorGroups, 0),
        id: 0,
        sgpPrt: findItem(sgpPrts, 0),
        target: findItem(targets, 0),
        title: 'Проведение ремонтных работ, км'
    });

    // 1 - Строительство школ в целях ликвидации трехсменных школ и дефицита ученических мест, ед.
    targetIndicators.push({
        group: findItem(targetIndicatorGroups, 0),
        id: 1,
        sgpPrt: findItem(sgpPrts, 1),
        target: findItem(targets, 1),
        title: 'Строительство школ в целях ликвидации трехсменных школ и дефицита ученических мест, ед.'
    });

    // 2 - Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %
    targetIndicators.push({
        group: findItem(targetIndicatorGroups, 1),
        id: 2,
        sgpPrt: findItem(sgpPrts, 2),
        target: findItem(targets, 0),
        title: 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %'
    });

    // 3 - Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест
    targetIndicators.push({
        group: findItem(targetIndicatorGroups, 1),
        id: 3,
        sgpPrt: findItem(sgpPrts, 2),
        target: findItem(targets, 1),
        title: 'Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест'
    });

    // дети #2 : 4-8
    (() => {
        // eslint-disable-next-line no-shadow
        const parent = findItem(targetIndicators, 2);

        // 4 - Общая протяженность автодорог местного значения, км
        addSimpleCopy(targetIndicators, parent, {
            id: 4,
            title: 'Общая протяженность автодорог местного значения, км',
            parent
        });

        // 5 - В т.ч. находящихся в хорошем и удовлетворительном состоянии, км
        addSimpleCopy(targetIndicators, parent, {
            id: 5,
            title: 'В т.ч. находящихся в хорошем и удовлетворительном состоянии, км',
            parent
        });

        // 6 - Изменение +/-
        addSimpleCopy(targetIndicators, parent, {
            id: 6,
            title: 'Изменение +/-',
            parent
        });

        // 7 - Протяженность автодорог, которые перейдут в неудовлетворительное состояние (износ), км
        addSimpleCopy(targetIndicators, parent, {
            id: 7,
            title: 'Протяженность автодорог, которые перейдут в неудовлетворительное состояние (износ), км',
            parent
        });

        // 8 - Протяженность автодорог, которые необходимо отремонтировать для достижения планового показателя
        addSimpleCopy(targetIndicators, parent, {
            id: 8,
            title: 'Протяженность автодорог, которые необходимо отремонтировать для достижения планового показателя',
            parent
        });
    })();

    // дети #3: 9-11
    (() => {
        // eslint-disable-next-line no-shadow
        const parent = findItem(targetIndicators, 3);

        // 9 - Изменение +/-
        addSimpleCopy(targetIndicators, parent, {
            id: 9,
            title: 'Изменение +/-',
            parent
        });

        // 10 - Прогноз прироста количества школьников в дефицитных школах, чел.
        addSimpleCopy(targetIndicators, parent, {
            id: 10,
            title: 'Прогноз прироста количества школьников в дефицитных школах, чел.',
            parent
        });

        // 11 - Количество ученических мест, которые необходимо создать для достижения показателя, единиц
        addSimpleCopy(targetIndicators, parent, {
            id: 11,
            title: 'Количество ученических мест, которые необходимо создать для достижения показателя, единиц',
            parent
        });
    })();
})();
const targetIndicatorMap: Map<number, Types.ITargetIndicator> = createMapById(targetIndicators);

const targetIndicatorDatas: Types.ITargetIndicatorData[] = [
    // 0 - Проведение ремонтных работ, км / РК
    {
        id: 0,
        type: 'republic',
        indicator: findItem(targetIndicators, 0),
        values: [
            {
                year: 2020,
                value: 4000,
            },
            {
                year: 2021,
                value: 4200
            },
            {
                year: 2022,
                value: 4400
            },
            {
                year: 2023,
                value: 4600
            },
            {
                year: 2024,
                value: 4800
            },
            {
                year: 2025,
                value: 5000
            }
        ]
    },

    // 1 - Проведение ремонтных работ, км / ОБЛ
    {
        id: 1,
        type: 'region',
        indicator: findItem(targetIndicators, 0),
        values: [
            {
                year: 2020,
                value: 336
            },
            {
                year: 2021,
                value: 196
            },
            {
                year: 2022,
                value: 367
            },
            {
                year: 2023,
                value: 362
            },
            {
                year: 2024,
                value: 351            },
            {
                year: 2025,
                value: 371
            }

        ]
    },

    // 2 - Строительство школ в целях ликвидации трехсменных школ и дефицита ученических мест, ед. / РК
    {
        id: 2,
        type: 'republic',
        indicator: findItem(targetIndicators, 1),
        values: [
            {
                year: 2020,
                value: 87
            },
            {
                year: 2021,
                value: 95
            },
            {
                year: 2022,
                value: 103
            },
            {
                year: 2023,
                value: 116
            },
            {
                year: 2024,
                value: 126
            },
            {
                year: 2025,
                value: 136
            }
        ]
    },

    // 3 - Строительство школ в целях ликвидации трехсменных школ и дефицита ученических мест, ед. / ОБЛ
    {
        id: 3,
        type: 'region',
        indicator: findItem(targetIndicators, 1),
        values: [
            {
                year: 2021,
                value: 6
            },
            {
                year: 2022,
                value: 3
            },
            {
                year: 2023,
                value: 4
            },
            {
                year: 2024,
                value: 2
            },
            {
                year: 2025,
                value: 1
            }
        ]
    },

    // 4 - Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %
    {
        id: 4,
        type: null,
        indicator: findItem(targetIndicators, 2),
        values: [
            {
                year: 2020,
                value: 86
            },
            {
                year: 2021,
                value: 87
            },
            {
                year: 2022,
                value: 89
            },
            {
                year: 2023,
                value: 91
            },
            {
                year: 2024,
                value: 93
            },
            {
                year: 2025,
                value: 95
            }
        ]
    },

    // 5 - Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест
    {
        id: 5,
        type: null,
        indicator: findItem(targetIndicators, 3),
        values: [
            {
                year: 2020,
                value: 8690
            },
            {
                year: 2021,
                value: 8585
            },
            {
                year: 2022,
                value: 5495
            },
            {
                year: 2023,
                value: 1850
            }
        ]
    }
];
const targetIndicatorDataMap: Map<number, Types.ITargetIndicatorData> = createMapById(targetIndicatorDatas);


const data: Types.IData = {
    targets,
    targetMap,
    targetIndicatorGroups,
    targetIndicatorGroupMap,
    sgpPrts,
    sgpPrtMap,
    targetIndicators,
    targetIndicatorMap,
    targetIndicatorDatas,
    targetIndicatorDataMap
};

export default data;