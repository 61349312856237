



























































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import * as Types from '../types';
import data from '../data';


interface IDataIndicator {
    indicator: Types.ITargetIndicator;
    items: Types.ITargetIndicatorData[];
}

interface IDataGroup {
    group: Types.ITargetIndicatorGroup;
    indicators: IDataIndicator[];
    indicatorMap: Map<number, IDataIndicator>;
}


const dataGroupMap: Map<number, IDataGroup> = new Map();
const dataGroups: IDataGroup[] = [];
data.targetIndicatorDatas.forEach((item) => {
    const indicator = item.indicator;
    if (indicator.parent !== undefined) {
        return;
    }

    const group = indicator.group;

    let dataGroup = dataGroupMap.get(group.id);
    if (dataGroup === undefined) {
        dataGroup = {
            group: group,
            indicators: [],
            indicatorMap: new Map()
        };
        dataGroupMap.set(group.id, dataGroup);
        dataGroups.push(dataGroup);
    }

    let dataIndicator = dataGroup.indicatorMap.get(indicator.id);
    if (dataIndicator === undefined) {
        dataIndicator = {
            indicator: indicator,
            items: []
        };
        dataGroup.indicatorMap.set(indicator.id, dataIndicator);
        dataGroup.indicators.push(dataIndicator);
    }

    dataIndicator.items.push(item);
});

const getSelectedGroups = (selectedTargets: Types.ITarget[]): Map<number, IDataGroup> => {
    const result: Map<number, IDataGroup> = new Map();

    dataGroups.forEach((dataGroup) => {
        dataGroup.indicators.forEach((dataIndicator) => {
            if (selectedTargets.includes(dataIndicator.indicator.target)) {
                let newDataGroup = result.get(dataIndicator.indicator.group.id);
                if (newDataGroup === undefined) {
                    newDataGroup = {
                        group: dataIndicator.indicator.group,
                        indicators: [],
                        indicatorMap: new Map()
                    };
                    result.set(newDataGroup.group.id, newDataGroup);
                }

                newDataGroup.indicators.push(dataIndicator);
                newDataGroup.indicatorMap.set(dataIndicator.indicator.id, dataIndicator);
            }
        });
    });

    return result;
};


const initialSelectedTargets: Types.ITarget[] = [];
const targetSelectOptions: object[] = [];
data.targets.forEach((target) => {
    initialSelectedTargets.push(target);
    targetSelectOptions.push({
        text: target.title,
        value: target
    });
});


@Component({
    name: 'Decomposition',
    components: {
        multiselect: Multiselect
    }
})
export default class Decomposition extends Vue {
    // #region Фильтр "Цели"

    private targets: Types.ITarget[] = data.targets;

    private selectedTargets: Types.ITarget[] = initialSelectedTargets;

    private targetSelectOptions: object[] = targetSelectOptions;

    // #endregion

    private get selectedGroups(): Map<number, IDataGroup> {
        return getSelectedGroups(this.selectedTargets);
    }

    private get selectedText(): String[] {
        const res = []
        for (const item of this.selectedTargets) {
            res.push(item.title)
        }
        return res;
    }

    private getDataValue(item: Types.ITargetIndicatorData, year: number): string {
        let value: Types.ITargetIndicatorDataValue | undefined;
        for (const testValue of item.values) {
            if (testValue.year === year) {
                value = testValue;
                break;
            }
        }

        if ((value !== undefined) && (typeof value.value === 'number')) {
            return value.value.toString();
        }
        return '-';
    }
}
